import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../../shared/services/config.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.configService.setDataConfig();
  }

  get dataConfig$() {
    return this.configService.dataConfigLayout;
  }

  logOut(event: any) {
    localStorage.clear();
    sessionStorage.clear();

    var allCookies = document.cookie.split(";");

    for (var i = 0; i < allCookies.length; i++)
      document.cookie =
        allCookies[i] + "=;expires=" + new Date(0).toUTCString();

    location.reload();
  }
}
