import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { pipe } from "rxjs";
import { ActivatedRoute, Params } from '@angular/router';
import { ChatsService } from 'src/app/services/chat/chats.service';
import { ProceduresService } from 'src/app/services/inbox/procedures.service';
import { first } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsAndConditionsModalComponent implements OnInit {
  procedureId: string;
  terms: any;
  isLoading : boolean;

  constructor(private procedureService: ProceduresService,
              private chatsService: ChatsService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
      this.isLoading = true;
      this.getProcedureId()
      this.procedureService.getTerms(this.procedureId).subscribe(res => {
        this.terms = res.result.terms;
        this.terms = this.convertText(this.terms);
        this.isLoading = false;
        }
      );
    }


    getProcedureId(){
      const ff_events = JSON.parse(sessionStorage.getItem("firstFormData"));
      // if(ff_events.procedureId === "DRAFT"){ 
      //   console.log("PROCEDURE ID DRAFT!");
      // }
      this.procedureId = ff_events.procedureId;
    }

    convertText(value: any) {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }

  }