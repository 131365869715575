import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Params } from "@angular/router";
import { clearEmptyValues } from "./../../shared/utils/index";
import { first } from "rxjs/operators";

export type Citizen = {
  citizen_name: string;
  citizen_email: string;
  citizen_cuil: string;
  citizen_telephone: string;
};
export type Agent = {
  agent_name: string;
  agent_cuil: string;
};
export type DraftInstance = {
  createdAt: Date;
  dashboard: string;
  link: string;
  type: string;
  subType: string;
};

type GetProcedureDocsTableResponse = {
  initialDocuments: string[];
  instances: DraftInstance[];
  procedureCode: string;
  procedureDescription: string;
  procedureExplanation: string;
  procedureRequirements: string;
  citizenData: Citizen;
  agentData: Agent;
  redirectionUrl: string;
  token_and_sign: any;
};

@Injectable({
  providedIn: "root",
})
export class ProceduresService {
  private urlapi_procedures = "v4/procedures";

  constructor(private http: HttpClient) {}

  makePost(url) {
    return this.http.get(
      url,{responseType: 'text'}
    );
  }

  getProcedures({ params }): Observable<any> {
    const urlProcedures = `${environment.BASE_URL}/${this.urlapi_procedures}/`;
    return this.http.get(urlProcedures, {
      params: this._generateGetInstancesParams(params),
    });
  }

  getProcedureDocsTable(
    procedureId
  ): Observable<GetProcedureDocsTableResponse> {
    return this.http
      .get<GetProcedureDocsTableResponse>(
        `${environment.BASE_URL}/${this.urlapi_procedures}/${procedureId}/docs_table`
      )
      .pipe(first());
  }

  getTerms(procedureId) : Observable<any>{
    const urlProcedures = `${environment.BASE_URL}/${this.urlapi_procedures}/terms/${procedureId}`;
    return this.http.get(urlProcedures);
  }

  _generateGetInstancesParams(params: Params): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    const cleanParams = clearEmptyValues(params);
    Object.keys(cleanParams).forEach((k) => {
      if (Array.isArray(params[k]))
        httpParams = httpParams.append(k, encodeURI(params[k]));
      else httpParams = httpParams.append(k, encodeURIComponent(params[k]));
    });
    return httpParams;
  }
}
