import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from "./core/layout/layout.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
  
    children: [
      { path: "", redirectTo: "instances", pathMatch: "full" },
      { path: 'instances', loadChildren: () => import('./inbox/inbox.module').then(m => m.InboxModule) }
    ], 
  },
  { path: "**", redirectTo: "instances" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
