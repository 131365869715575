<lea-mode-container>
  <lea-toolbar>
    <div class="toolbar-logo">
      <div>
        <img src="../../../assets/img/guayaquil-blanco.svg" alt="Moorea" />
        <p>Tramites</p>
      </div>
    </div>
    <div class="toolbar-user">
      <p class="hidden-xs" *ngIf="dataConfig$?.cn">{{ dataConfig$.cn }}</p>
      <a href="" target="_blank" class="hidden-xs btn-ayuda">
        <div class="toolbar-button">
          <lea-icons icon="icon-question" size="20"></lea-icons>
          <span class="hidden-sm">Ayuda</span>
        </div>
      </a>
      <div class="toolbar-button" (click)="logOut($event)">
        <lea-icons icon="icon-exit" size="20"></lea-icons>
        <span role="button" class="hidden-xs">Cerrar sesión</span>
      </div>
    </div>
  </lea-toolbar>
  <div class="page-container">
    <router-outlet> </router-outlet>
  </div>
</lea-mode-container>