// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL:"/api",
  FILE_UPLOAD_URL: "/api",
  matomoConfig: {
    scriptUrl: 'https://matomo.l01.leafnoise.io/matomo.js',
    trackerUrl: 'https://matomo.l01.leafnoise.io/matomo.php',
    siteId: 1,
    downloadExtensions: ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'doc', 'docx'],
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
