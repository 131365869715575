import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Params } from "@angular/router";
import { clearEmptyValues } from "./../../shared/utils/index";

@Injectable({
  providedIn: "root",
})
export class InstancesService {
  private urlapi_count_tab = "v4/instances/count";
  private urlapi_instances_tab = "v4/instances";
  private urlapi_history = "v4/instances/history";

  constructor(private http: HttpClient) {}

  getTabCount(tab_code): Observable<any> {
    const urlCount = `${environment.BASE_URL}/${this.urlapi_count_tab}/${tab_code}`;
    return this.http.get(urlCount);
  }

  getInstances(tab_code): Observable<any> {
    const urlTabs = `${environment.BASE_URL}/${this.urlapi_instances_tab}/${tab_code}`;
    return this.http.get(urlTabs);
  }

  getInstancesPage({ tab_code, params }): Observable<any> {
    const urlTabs = `${environment.BASE_URL}/${this.urlapi_instances_tab}/${tab_code}`;
    return this.http.get(urlTabs, {
      params: this._generateGetInstancesParams(params),
    });
  }

  getHistory(idInstance): Observable<any> {
    const urlHistory = `${environment.BASE_URL}/${this.urlapi_history}/${idInstance}`;
    return this.http.get(urlHistory);
  }

  create({ formData, procedureId, params }) {
    return this.http.post(
      `${environment.BASE_URL}/v4/instances/`,
      { formData, procedureId },
      { params: this._generateGetInstancesParams(params) }
    );
  }

  updateForm(
    instanceId: string,
    formId: string,
    submitType: string,
    formData: any,
    module: string,
    procedureId: string
  ): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/v4/instances/${instanceId}/forms/${formId}`,
      {
        submitType,
        formData,
      },
      {
        params: this._generateGetInstancesParams({
          module: module,
          procedureId: procedureId,
        }),
      }
    );
  }

  _generateGetInstancesParams(params: Params): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    const cleanParams = clearEmptyValues(params);
    Object.keys(cleanParams).forEach((k) => {
      if (Array.isArray(params[k]))
        httpParams = httpParams.append(k, encodeURI(params[k]));
      else httpParams = httpParams.append(k, encodeURIComponent(params[k]));
    });
    return httpParams;
  }

  sortInformation(tab_code, val): Observable<any> {
    if (val == 0) {
      const urlTabs = `${environment.BASE_URL}/${this.urlapi_instances_tab}/${tab_code}?sort=-createdAt`;
      return this.http.get(urlTabs);
    } else {
      const urlTabs = `${environment.BASE_URL}/${this.urlapi_instances_tab}/${tab_code}?sort=+createdAt`;
      return this.http.get(urlTabs);
    }
  }

  //{{root}}/v4/procedures/<procedure_id>/initialInteractiveDocuments ==> [(String)]

  getInitialInteractiveDocuments(procedure_id): Observable<any> {
    const docs = `${environment.BASE_URL}/${procedure_id}/initialInteractiveDocuments`;
    return this.http.get(docs);
  }
}
