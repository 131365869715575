// src/mocks/handlers.js
import { rest } from "msw";
export const handlers = [
  // rest.get(
  //   "http://l01.leafnoise.io:5556/v4/procedures/607eeca03e8b9f0013227b77/forms/first",
  //   (req, res, ctx) => {
  //     return res(
  //       ctx.status(409),
  //       ctx.json({
  //         exception: "Según nuestros registros",
  //         message: "Según nuestros registros",
  //       })
  //     );
  //   }
  // ),
];
