<div class="modal-welcome" role="dialog">
    <div class="content-welcome">
        <p>¡Hola, <span class="boldy">{{userName}}</span>! Si querés iniciar un nuevo trámite ingresá a "<span class="boldy">Iniciar atención</span>".
         Si ya iniciaste un trámite y querés conocer su estado, buscalo en "<span class="boldy">Tus atenciones</span>".
        </p> 
    </div>
</div>

<div class="center-items">
    <lea-buttons shape="raised center-button" class="raised accent" color="accent" (click)="firstComponentFunction()">
      Aceptar
  </lea-buttons>
  </div>