import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const Material = [
  CommonModule,
  MatSnackBarModule,
]

@NgModule({
  declarations: [],
  imports: [Material],
  exports: [Material]
})

export class MaterialModule { }