import { Component, OnInit, Input } from '@angular/core';
import { EventEmitterService } from 'src/app/services/modal/event-emitter.service';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {

  @Input() userName: string;

  constructor(private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
  }

  setHideModal(){
    sessionStorage.setItem("welcomeModal","true");
  }
  
  firstComponentFunction(){    
    this.setHideModal();
    this.eventEmitterService.onFirstComponentButtonClick();    
  }   
}
