import { toCode } from "./strUtils";
export { toCode };

export const isEmpty = (value): boolean =>
  (Array.isArray(value) && value.length === 0) ||
  value === "" || value === undefined || value === null ||
  (typeof value === "object" && Object.keys(value).length === 0);

export const clearEmptyValues = (o: any) => {
  for (const k of Object.keys(o)) {
    if (typeof o[k] === "object" && o[k] !== null) clearEmptyValues(o[k]);
    if (isEmpty(o[k])) delete o[k];
  }
  return o;
};

export const _pluck = (array: Array<any>, key: string) => {
  return array.map(o => o[key]);
};

export const parseMarkdown = (markdownText):string => {
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
    .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
    .replace(/\*(.*)\*/gim, '<i>$1</i>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2' target='_blank'>$1</a>")
    .replace(/(?:\r\n|\r|\n)/g, '<br>')
  return htmlText.trim()
}