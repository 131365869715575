import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Params } from "@angular/router";
import { clearEmptyValues } from "./../../shared/utils/index";

@Injectable({
  providedIn: "root",
})
export class ChatsService {
  private urlapi_chats = "v4/instances";

  constructor(private http: HttpClient) {}

  getEventsCitizen(instanceId): Observable<any> {
    const url = `${environment.BASE_URL}/${this.urlapi_chats}/${instanceId}/interactive/citizen`;
    return this.http.get(url);
  }
  // v4/procedures/<procedure_id>/docs_table

  getAgentData(procedureId): Observable<any> {
    const url = `${environment.BASE_URL}/v4/procedures/${procedureId}/docs_table`;
    return this.http.get(url);
  }
  
  sendTextChat(
    instanceId: string,
    data: any,
    procedureId: string
  ): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/${this.urlapi_chats}/${instanceId}/interactive/generic`,
      {
        ...data,
        procedureId: procedureId,
      }
    );
  }

  uploadFile(
    instanceId: string,
    data: any,
    procedureId: string
  ): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/${this.urlapi_chats}/${instanceId}/interactive/generic`,
      {
        ...data,
        procedureId: procedureId,
      }
    );
  }

  discardDocument(instanceId: string, data: any): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL}/${this.urlapi_chats}/${instanceId}/interactive/discardToSendDocument`,
      data
    );
  }

  citizenUpdateChat(instanceId: string, data: any): Observable<any> {
    return this.http.put(
      `${environment.BASE_URL}/${this.urlapi_chats}/${instanceId}/interactive/citizen/update`,
      data
    );
  }

  _generateGetInstancesParams(params: Params): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    const cleanParams = clearEmptyValues(params);
    Object.keys(cleanParams).forEach((k) => {
      if (Array.isArray(params[k]))
        httpParams = httpParams.append(k, encodeURI(params[k]));
      else httpParams = httpParams.append(k, encodeURIComponent(params[k]));
    });
    return httpParams;
  }

  discardDraft(instanceId: string): Observable<any> {
    return this.http.delete(
      `${environment.BASE_URL}/${this.urlapi_chats}/${instanceId}/discard_draft`
    );
  }
}
