import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './core/layout/layout.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigService } from './shared/services/config.service';
import { RejectedModalComponent } from './chat/rejected-modal/rejected-modal.component';
import { MissingModalComponent } from './chat/missing-modal/missing-modal.component';
import { TermsAndConditionsModalComponent } from './chat/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { DraftModalComponent } from './chat/draft-modal/draft-modal.component';
import { MatomoModule } from 'ngx-matomo';
import { WelcomeModalComponent } from './chat/welcome-modal/welcome-modal.component';
import { LeafnoiseComponentsModule } from 'lea-components';
import { environment } from 'src/environments/environment';
import { MaterialModule } from './shared/material/material.module';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    RejectedModalComponent,
    WelcomeModalComponent,
    MissingModalComponent,
    TermsAndConditionsModalComponent,
    DraftModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    LeafnoiseComponentsModule.forRoot(),
    MatomoModule.forRoot({
      scriptUrl: environment.matomoConfig.scriptUrl,
      trackers: [{
        trackerUrl: environment.matomoConfig.trackerUrl,
        siteId: environment.matomoConfig.siteId
      }],
      routeTracking: {
        enable: true
      }
    })
  ],
  providers: [ConfigService],
  bootstrap: [AppComponent]
})

export class AppModule { }