import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rejected-modal',
  templateUrl: './rejected-modal.component.html',
  styleUrls: ['./rejected-modal.component.scss']
})
export class RejectedModalComponent implements OnInit {

  @Input() docDescription: string;
  @Input() rejectionReason: string;

  constructor() { }

  ngOnInit(): void {
  }

}
