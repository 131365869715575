import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-missing-modal',
  templateUrl: './missing-modal.component.html',
  styleUrls: ['./missing-modal.component.scss']
})
export class MissingModalComponent implements OnInit {
  
  @Input() missingDocs: any[];
  
  constructor() { }

  ngOnInit(): void {
    
  }

}
