import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { InstancesService } from 'src/app/services/inbox/instances.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  
  private urlapi_config = 'v4/instances/config';
  public configPublic;

  constructor(private http: HttpClient,private instancesServices: InstancesService) {  }

  getConfig(){
    const urlConf = `${environment.BASE_URL}/${this.urlapi_config}`;    
    return this.http.get(urlConf);  
  }

  removeConfig(){
    if(sessionStorage.getItem('config')){
      sessionStorage.removeItem('config')
    }
  }

  removeConfigTabs(){
    if(sessionStorage.getItem('configTabs')){
      sessionStorage.removeItem('configTabs')
    }
  }

  setDataConfig(){   
    this.removeConfig();   
    this.getConfig().subscribe((data:any)=>{ 
      sessionStorage.setItem('configLayout', JSON.stringify(data)); 
      this.buildLabels(data);
    })
    
  }

  buildLabels(data){
    let auxcont = 0;
    let tabs = [];
    for (let [index,i] of data.tabs.entries()) {
      this.instancesServices.getTabCount(i.code).subscribe(cant => {
        tabs.push({
          descriptionsCounts:`${data.tabs[index].description} (${cant.count})`,
          totalInstances: cant,
          code: `${data.tabs[index].code}` 
        });
        auxcont++;
        if(auxcont === data.tabs.length){
          this.setConfigTabs(tabs);
          sessionStorage.setItem('config', JSON.stringify(data));
        }
      }, error =>{
        //console.log(error);
      });
    }
  }

  setConfigTabs(data){    
    this.removeConfigTabs();
    const item_order = ["proceso","finalizados","informacion"];
    data = data.sort((a, b) => item_order.indexOf(a.code) - item_order.indexOf(b.code));
    sessionStorage.setItem('configTabs', JSON.stringify(data));
  }

  get dataConfig(){
    return JSON.parse(sessionStorage.getItem('config'));        
  }

  get dataConfigLayout(){
    return JSON.parse(sessionStorage.getItem('configLayout'));        
  }

  get dataConfigTabs(){
    return JSON.parse(sessionStorage.getItem('configTabs'));        
  }
}
